<template>
    <footer>
        <nt-footer-btn v-if="promptPage" :figure="promptFigure"></nt-footer-btn>
        <nt-footer-btn v-else-if="toPlantPages" :figure="toPlantsFigure"></nt-footer-btn>
    </footer>
</template>

<script>
import NtFooterBtn from '@/components/footerBtn.vue';
import { currentPage } from '@/main';

export default {
    name: 'NtFooter',
    data() {
        return {
            promptFigure: {
                text: {
                    de: 'Neuer Prompt',
                    en: 'New Prompt'
                },
                url: './pic/refresh.png',
                alt: 'Refresh'
            },
            toPlantsFigure: {
                text: {
                    de: 'Zu den Pflanzen',
                    en: 'To the plants'
                },
                url: './pic/arrow.png',
                alt: 'Arrow'
            },
        }
    },
    components: {
        NtFooterBtn,
    },
    computed: {
        promptPage() {
            return currentPage.page === 'prompt';
        },
        toPlantPages() {
            return currentPage.page === 'home' || currentPage.page === 'focus';
        },
    }
}
</script>
<style scoped>

footer {
    width: 100%;
    max-width: 428px;
    display: flex;
    flex-flow: row nowrap;
    justify-content: center;
    position: fixed;
    bottom: 0;
}

</style>