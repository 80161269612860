<template>
    <nav>
        <nt-nav-toggle></nt-nav-toggle>
        <nt-lang-switch></nt-lang-switch>
    </nav>
</template>

<script>
import NtNavToggle from './navToggle.vue';
import NtLangSwitch from './langSwitch.vue';

export default {
    name: 'NtNavigation',
    components: {
        NtLangSwitch,
        NtNavToggle,
    },
}
</script>
<style scoped>
nav {
    width: 100%;
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
}
</style>