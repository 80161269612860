<template>
    <div class="content">
        <h2 v-if="language" v-html="content.title.en"></h2>
        <h2 v-else v-html="content.title.de"></h2>
        <section v-for="item in content.sections" :key="item.title">
            <h3 v-if="language && item.headline.en !== ''" v-html="item.headline.en"></h3>
            <h3 v-else-if="item.headline.de !== ''" v-html="item.headline.de"></h3>
            <p v-if="language && item.paragraph.en !== ''" v-html="item.paragraph.en"></p>
            <p v-else-if="item.paragraph.de !== ''" v-html="item.paragraph.de"></p>
        </section>
    </div>
</template>
<script>
import { currentLang } from "@/main";
import { currentPage } from "@/main";
import imprint from "@/content/imprint.json";
import credits from "@/content/credits.json";
import info from "@/content/info.json";
import peatland from "@/content/peatland.json";
import contact from "@/content/contact.json";
import article from "@/content/article.json";

export default {
    name: 'NtInfo',
    computed: {
        language() {
            return currentLang.language.state;
        },
        content() {
            return this.currentContent();
        }
    },
    methods: {
        currentContent() {
            switch (currentPage.page) {
                case 'info':
                    return info;
                case 'peatland':
                    return peatland;
                case 'credits':
                    return credits;
                case 'contact':
                    return contact;
                case 'imprint':
                    return imprint;
                case 'article':
                    return article;
                default:
                    return;
            }
        }
    },
}
</script>

<style scoped>
h2, h3, p {
    background-color: var(--mainBgColor);
    color: var(--mainTextColor);
    margin: 0 auto 1vh 0;
    padding: 1vh;
}

p {
    width: fit-content;
}
</style>