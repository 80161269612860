<template>
    <header :class="{ active: showNav }">
        <nt-navigation></nt-navigation>
        <nt-nav-menu v-if="showNav"></nt-nav-menu>
    </header>
</template>

<script>
import NtNavigation from './navigation.vue';
import NtNavMenu from './navMenu.vue';
import { navigationClick } from '@/main';

export default {
    name: 'NtHeader',
    computed: {
        showNav() {
            return navigationClick.state;
        },
    },
    components: {
        NtNavigation,
        NtNavMenu,
    },
}
</script>
<style scoped>
header {
    width: 100%;
    max-width: 428px;
    height: 10vh;
    padding: 1vw;
    z-index: 1;
    position: fixed;
}

.active {
    background-color: var(--mainBgColorTransparent);
    color: var(--mainTextColor);
}
</style>