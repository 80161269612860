<template>
    <main>
        <component :is="currentPageComponent"></component>
    </main>
</template>

<script>
import { currentPage } from '@/main';
import NtHome from '@/components/pages/home.vue';
import NtInfo from '@/components/pages/info.vue'
import NtPrompt from '@/components/pages/prompt.vue';
import NtOverview from '@/components/pages/overview.vue';
import NtFocus from '@/components/pages/focus.vue';

export default {
    name: 'NtMain',
    data() {
        return {
            pages: {
                'home': 'Home',
                'info': 'Info',
                'prompt': 'Prompt',
                'overview': 'Overview',
                'focus': 'Focus',
                'peatland': 'Info',
                'article' : 'Info',
                'credits': 'Info',
                'contact': 'Info',
                'imprint': 'Info',
            },
        }
    },
    components: {
        NtHome,
        NtInfo,
        NtPrompt,
        NtOverview,
        NtFocus,
    },
    computed: {
        currentPageComponent() {
            return 'Nt' + this.pages[currentPage.page];
        },
    },
}

</script>

<style scoped>
main {
    width: 80%;
    flex: 1 0 80%;
    display: flex;
    flex-flow: column nowrap;
    justify-content: center;
    align-items: center;
    margin: 10vh auto 5vh;
}
</style>