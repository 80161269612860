<template>
    <div class="content">
        <figure>
            <img :src="plant.nt_image_url_big" :alt="plant.nt_plant_name_la">
        </figure>
        <figcaption v-if="language">
            {{ plant.nt_plant_name_la }} // {{ plant.nt_plant_name_en }}
        </figcaption>
        <figcaption v-else>
            {{ plant.nt_plant_name_la }} // {{ plant.nt_plant_name_de }}
        </figcaption>
        <p v-if="language">{{ plant.nt_plant_text_en }}</p>
        <p v-else>{{ plant.nt_plant_text_de }}</p>
        <p v-if="language">
            <a :href="plant.nt_wiki_url_en" :title="plant.nt_plant_name_la" target="_blank">External link</a>
        </p>
        <p v-else>
            <a :href="plant.nt_wiki_url_de" :title="plant.nt_plant_name_la" target="_blank">Externer Link</a>
        </p>
    </div>
</template>
<script>
import { currentLang } from "@/main";
import { focus } from "@/main";

export default {
    name: 'NtFocus',
    data() {
        return {
            plant: focus.plant,
        }
    },
    computed: {
        language() {
            return currentLang.language.state;
        },
    }
}
</script>
<style scoped>
.content {
    align-items: center;
    margin-bottom: 10vh;
}

figure, figcaption, p {
    padding: 1vh;
    margin: 0 auto 1vh;
}

figcaption, p {
    background-color: var(--mainBgColor);
    color: var(--mainTextColor);
}

figure {
    width: clamp(5vw, 16rem, 360px);
    height: clamp(5vw, 16rem, 360px);
    border: 2px solid var(--mainBgColor);
    border-radius: clamp(2.5vw, 8rem, 180px);
    overflow: hidden;
    background-color: lightgrey;
}

img {
    width: 100%;
    height: auto;
}

figcaption {
    text-align: center;
    font-weight: bold;
}
</style>