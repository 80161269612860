<template>
    <button @click="navClick" type="button" :id="itemId" :class="{ active: isActive }" :data-slug="itemSlug" class="navItem">
        {{ itemValue }}
    </button>
</template>
<script>
import { currentPage } from "@/main";
import { navigationClick } from "@/main";

export default {
    name: 'NtNavMenuItem',
    props: {
        itemId: Number,
        itemValue: String,
        itemSlug: String,
    },
    computed: {
        isActive() {
            return this.itemSlug === currentPage.page;
        },
    },
    methods: {
        navClick(event) {
            currentPage.page = event.target.getAttribute('data-slug');
            navigationClick.state = false;
        }
    }
}
</script>
<style scoped>
.navItem {
    width: 100%;
    background: none;
    color: #333;
    border: none;
    padding: 1vh 0;
    margin: 0 auto;
}

.navItem:hover:not(.active) {
    font-weight: bold;
}
.active {
    font-weight: bold;
    color: black;
}
</style>