import Vue from 'vue'
import App from './App.vue'

export const currentLang = Vue.observable({
  language: {
    state: true,
    text: 'de',
  },
});

export const navigationClick = Vue.observable({
  state: false,
});

export const currentPage = Vue.observable({
  page: 'home',
});

export const focus = Vue.observable({
  plant: null,
});

export const currentPromptNumber = Vue.observable({
  number: null,
});

Vue.config.productionTip = false

new Vue({
  render: h => h(App),
}).$mount('#app')
