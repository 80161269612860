<template>
  <div id="app">
    <nt-header></nt-header>
    <nt-main></nt-main>
    <nt-footer></nt-footer>
  </div>
</template>

<script>
import NtHeader from '@/components/header.vue';
import NtMain from '@/components/main.vue';
import NtFooter from '@/components/footer.vue';

export default {
  name: 'App',
  components: {
    NtHeader,
    NtMain,
    NtFooter,
  },
}
</script>

<style>
#app {
  width: 100%;
  min-height: 100%;
  margin: 0 auto;
  background-color: #333;
  background-image: url('./assets/nt-background.jpg');
  background-repeat: no-repeat;
  background-size: cover;
  background-attachment: fixed;
  background-position: center;
  color: #f4f43e;
  display: flex;
  flex-flow: column nowrap;
  justify-content: flex-start;
  align-items: flex-start;
  box-shadow: 0px 0px 4px #222;
}

@media only screen and (min-width: 429px) {
  #app {
    background-size: contain;
  }
}
</style>
