<template>
    <div id="menuWrapper" @click="clickOutside">
        <ul id="menuList">
            <li v-for="item in pages" :key="item.id">
                <nt-nav-menu-item v-if="language" :itemId="item.id" :itemValue="item.title.en" :itemSlug="item.slug"></nt-nav-menu-item>
                <nt-nav-menu-item v-else :itemId="item.id" :itemValue="item.title.de" :itemSlug="item.slug"></nt-nav-menu-item>
            </li>
        </ul>
        <div id="spacer"></div>
    </div>
</template>

<script>
import NtNavMenuItem from "./navMenuItem.vue";
import { navigationClick } from "@/main";
import { currentLang } from "@/main";

export default {
    name: 'NtNavMenu',
    components: {
        NtNavMenuItem,
    },
    data: function() {
        return {
            pages: [
                { id: 1, title: { de: 'Home', en: 'Home' }, slug: 'home' },
                { id: 2, title: { de: 'Nastien & Tropismen Info', en: 'Nastien & Tropismen Info' }, slug: 'info' },
                { id: 3, title: { de: 'Bekomme einen Vorschlag', en: 'Get a Prompt' }, slug: 'prompt' },
                { id: 4, title: { de: 'Pflanzen Übersicht', en: 'Plant Overview' },slug: 'overview' },
                { id: 5, title: { de: 'Moor Info', en: 'Peatland Info' }, slug: 'peatland' },
                { id: 6, title: { de: 'Artikel', en: 'Article' }, slug: 'article' },
                { id: 7, title: { de: 'Credits', en: 'Credits' }, slug: 'credits' },
                { id: 8, title: { de: 'Kontakt', en: 'Contact' }, slug: 'contact' },
                { id: 9, title: { de: 'Impressum & Datenschutzerklärung', en: 'Imprint & Privacy Policy' }, slug: 'imprint' },
            ],
        }
    },
    computed: {
        language() {
            return currentLang.language.state;
        }
    },
    methods: {
        clickOutside(event) {
            if (!document.getElementById('menuList').contains(event.target)) {
                navigationClick.state = false;
            }
        }
    }
}
</script>
<style scoped>
#menuWrapper {
    width: 100vw;
    max-width: 428px;
    height: 100vh;
    border: none;
    position: fixed;
    top: 10vh;
    left: 50%;
    transform: translate(-50%, 0);
    display: flex;
    flex-flow: column nowrap;
    justify-content: flex-start;
    align-items: flex-start;
}

ul, li {
    width: 100%;
}

ul {
    box-shadow: 0 1px 1px #666;
    background-color: var(--mainBgColorTransparent);
    padding: 0 0 5vh;
}

#spacer {
    width: 100%;
    height: 100%;
    background-color: var(--mainTextColorTransparent);
}
</style>