<template>
<div class="content">
    <h1 v-if="language">{{ title.en }}</h1>
    <h1 v-else>{{ title.de }}</h1>
    <button v-if="language" @click="toPromptPage" type="button" :title="button.titleText.en" id="promptBtn">
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><!--! Font Awesome Pro 6.2.0 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2022 Fonticons, Inc. --><path d="M512 64c0 113.6-84.6 207.5-194.2 222c-7.1-53.4-30.6-101.6-65.3-139.3C290.8 78.3 364 32 448 32h32c17.7 0 32 14.3 32 32zM0 128c0-17.7 14.3-32 32-32H64c123.7 0 224 100.3 224 224v32 96c0 17.7-14.3 32-32 32s-32-14.3-32-32V352C100.3 352 0 251.7 0 128z"/></svg>
        <span>{{ button.imperative.en }}</span>
    </button>
    <button v-else @click="toPromptPage" type="button" :title="button.titleText.de" id="promptBtn">
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><!--! Font Awesome Pro 6.2.0 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2022 Fonticons, Inc. --><path d="M512 64c0 113.6-84.6 207.5-194.2 222c-7.1-53.4-30.6-101.6-65.3-139.3C290.8 78.3 364 32 448 32h32c17.7 0 32 14.3 32 32zM0 128c0-17.7 14.3-32 32-32H64c123.7 0 224 100.3 224 224v32 96c0 17.7-14.3 32-32 32s-32-14.3-32-32V352C100.3 352 0 251.7 0 128z"/></svg>
        <span>{{ button.imperative.de }}</span>
    </button>
</div>
</template>

<script>
import { currentLang } from '@/main';
import { currentPage } from '@/main';

export default {
    name: 'NtHome',
    data: function() {
        return {
            title: {
                de: 'Nastien & Tropismen',
                en: 'Nastien & Tropismen'
            },
            button: {
                titleText: {
                    de: 'Für einen Vorschlag den Button klicken!',
                    en: 'Click this button to get a prompt!',
                },
                imperative: {
                    de: 'Für einen Vorschlag drücken',
                    en: 'push to get a prompt',
                },
            },
        }
    },
    computed: {
        language() {
            return currentLang.language.state;
        }    
    },
    methods: {
        toPromptPage() {
            currentPage.page = 'prompt';
        }
    }
}
</script>
<style scoped>
h1 {
    text-align: center;
    color: var(--mainBgColor);
    margin: 0 auto 1vh;
}

#promptBtn {
    width: fit-content;
    display: flex;
    flex-flow: row nowrap;
    justify-content: center;
    align-items: center;
    background-color: #1a1a1a;
    color: white;
    margin: 0 auto;
    border: none;
    border-radius: 1vh;
    padding: 1vh;
}

span {
    height: 1rem;
    line-height: 1rem;
    margin: 0.5rem 1vh 0.5rem;
}

svg {
    width: 1rem;
    height: 1rem;
    fill: var(--mainBgColor);
    margin: 0.5rem 0 0.5rem 1vh;
}

img {
    margin-right: 1vh;
}
</style>