<template>
    <div class="content">
        <h1 v-if="language">{{ pagetitle.en }}</h1>
        <h1 v-else>{{ pagetitle.de }}</h1>
        <figure id="finalPrompt">
            <img id="promptImage" :src="plant.nt_image_url_small">
            <figcaption v-if="language" id="promptText">{{ prompt.nt_prompt_text_en }}</figcaption>
            <figcaption v-else id="promptText">{{ prompt.nt_prompt_text_de }}</figcaption>
        </figure>
        <div id="promptControls">
            <button type="button" @click="downloadPrompt">download</button>
            <button type="button" @click="visitPlant(plant)">visit plant</button>
        </div>
        <div id="infoCardWrapper">
            <figure id="infoCard">
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><!--! Font Awesome Pro 6.2.0 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2022 Fonticons, Inc. --><path d="M256 512c141.4 0 256-114.6 256-256S397.4 0 256 0S0 114.6 0 256S114.6 512 256 512zM216 336h24V272H216c-13.3 0-24-10.7-24-24s10.7-24 24-24h48c13.3 0 24 10.7 24 24v88h8c13.3 0 24 10.7 24 24s-10.7 24-24 24H216c-13.3 0-24-10.7-24-24s10.7-24 24-24zm40-144c-17.7 0-32-14.3-32-32s14.3-32 32-32s32 14.3 32 32s-14.3 32-32 32z"/></svg>
                <figcaption v-if="language" id="infoCardText">{{ infotext.en }}</figcaption>
                <figcaption v-else id="infoCardText">{{ infotext.de }}</figcaption>
            </figure>
        </div>
    </div>
</template>
<script>
import plants from "@/data/plants.json";
import prompts from "@/data/prompts.json";
import { focus } from "@/main";
import { currentLang } from "@/main";
import { currentPromptNumber } from "@/main";
import { currentPage } from "@/main";
import { navigationClick } from "@/main";

export default {
    name: 'NtPrompt',
    data() {
        return {
            pagetitle: {
                de: 'Dein Vorschlag',
                en: 'Your personal prompt'
            },
            infotext: {
                de: 'Lade deinen Vorschlag herunter und teile ihn',
                en: 'Download your personal prompt and share it',
            },
        }
    },
    computed: {
        language() {
            return currentLang.language.state;
        },
        prompt() {
            return this.getPrompt();
        },
        plant() {
            return this.getPlant();
        },
    },
    created() {
        let numberOfPrompts = prompts.list.length;
        currentPromptNumber.number = Math.floor(Math.random() * numberOfPrompts) + 1;
    },
    methods: {
        getPrompt() {
            let promptId = currentPromptNumber.number;
            function findPrompt(prompt) {
                return prompt.nt_prompt_id === String(promptId);
            }
            return prompts.list.find(findPrompt);
        },
        getPlant() {
            let currentPrompt = this.prompt;
            function findPlant(plant) {
                return plant.nt_plant_id === String(currentPrompt.nt_plant_id);
            }
            return plants.list.find(findPlant);
        },
        preparePrompt(promptText) {
            let promptLines = [];
            const maxPos = 42;

            // Variable for last whitespace position before the line break
            let lastWhite = 0;

            // Function to find whitespace positions
            function findWhitespaces(stringText, startPos) {
                return stringText.indexOf(" ", startPos);
            }

            while (promptText.length > maxPos) {
                // Find the whitespace position for the line break
                do {
                    lastWhite = findWhitespaces(promptText, lastWhite + 1);
                } while (findWhitespaces(promptText, lastWhite + 1) < maxPos && findWhitespaces(promptText, lastWhite + 1) > 0);

                let textLine = promptText.slice(0, lastWhite);
                promptLines.push(textLine);

                // Set new promptText
                promptText = promptText.slice(lastWhite).trim();
                
                // Reset whitespace position
                lastWhite = 0;
            }

            promptLines.push(promptText);

            return promptLines;
        },
        downloadPrompt() {
            const canvas = document.createElement('canvas');
            canvas.width = 1080;
            canvas.height = 1260;

            const ctx = canvas.getContext('2d');

            const img = new Image();
            img.src = this.plant.nt_image_url_big;

            let text = this.preparePrompt(this.language ? this.prompt.nt_prompt_text_en : this.prompt.nt_prompt_text_de);

            img.onload = () => {
                ctx.fillStyle = 'lightgrey';
                ctx.fillRect(0, 0, 1080, 1260);
                ctx.drawImage(img, 0, 0);
                ctx.font = 'bold 36px sans-serif';
                ctx.textAlign = 'center';
                ctx.fillStyle = 'black';

                text.forEach((element, index) => {
                    let lineHeight = 1120 + (index * 42);
                    ctx.fillText(element, canvas.width/2, lineHeight, 1040);
                });

                const promptUrl = canvas.toDataURL('image/png', 1.0);

                const helperLink = document.createElement('a');
                helperLink.href = promptUrl;
                helperLink.download = this.plant.nt_plant_name_la + '.png';
                helperLink.click();
            }
        },
        visitPlant(plant) {
            focus.plant = plant;
            currentPage.page = 'focus';
            navigationClick.state = false;
        }
    }
}
</script>
<style scoped>
.content {
    margin-bottom: 10vh;
}

h1 {
    text-align: center;
    color: var(--mainBgColor);
    margin: 0 0 1vh;
}

#finalPrompt {
    width: 100%;
    height: fit-content;
    border: 2px solid var(--mainBgColor);
    background-color: lightgrey;
    display: flex;
    flex-flow: column nowrap;
    justify-content: center;
    align-items: center;
    margin: 0 auto 1vh;
}

#promptImage {
    width: auto;
    height: 80%;
}

#promptText {
    font-size: 1rem;
    font-weight: bold;
    color: black;
    text-align: center;
    padding: 0 1vh;
}

#promptControls {
    width: 100%;
    display: flex;
    flex-flow: row wrap;
    justify-content: space-between;
    margin: 0 auto 1vh;
}

button {
    flex: 0 0 auto;
    background-color: var(--mainBgColor);
    color: var(--mainTextColor);
    font-weight: bold;
    border: none;
    padding: 1vh;
}

button:hover {
    color: black;
}

#infoCardWrapper {
    width: 100%;
    display: flex;
    flex-flow: row wrap;
    justify-content: flex-start;
    margin: 0 auto;
}

#infoCard {
    width: 72%;
    background-color: rgba(255, 255, 255, 0.6);
    color: black;
    display: flex;
    flex-flow: row nowrap;
    justify-content: flex-start;
    align-items: center;
    padding: 1vh;
}

svg {
    width: 33%;
    height: auto;
    fill: black;
    margin: 0 1vh 0 0;
}

#infoCardText {
    font: normal normal normal 0.75rem auto inherit;
}
</style>