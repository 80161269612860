<template>
    <button v-on:click="switchLang()" id="langSwitch" :class="{ active: isActive }">{{ language }}</button>
</template>

<script>
import { currentLang } from '@/main';
import { navigationClick } from '@/main';

export default {
    name: 'NtLangSwitch',
    computed: {
        language() {
            return currentLang.language.text;
        },
        isActive() {
            return navigationClick.state;
        }
    },
    methods: {
        switchLang() {
            if (currentLang.language.text == 'de') {
                currentLang.language.state = false;
                currentLang.language.text = 'en';
            } else {
                currentLang.language.state = true;
                currentLang.language.text = 'de';
            }
        }
    }
}
</script>
<style scoped>
button {
    background-color: inherit;
    color: var(--mainBgColor);
    font-weight: bold;
    border: none;
    font-size: 2.4vh;
    margin: 0 1vh;
}

button:hover {
    font-weight: normal;
}

.active {
    color: var(--mainTextColorTransparent);
}
</style>