<template>
    <div class="content">
        <ul>
            <li v-for="item in plants" :key="item.nt_plant_id" @click="focusPlant(item)">
                <figure>
                    <img :src="item.nt_image_url_small" :alt="item.nt_plant_name_la">
                </figure>
                <figcaption>{{ item.nt_plant_name_la }}</figcaption>
            </li>
        </ul>
    </div>
</template>
<script>
import { currentPage } from "@/main"
import { navigationClick } from "@/main";
import plantdata from "@/data/plants.json";
import { focus } from "@/main";

export default {
    name: 'NtOverview',
    data() {
        return {
            plants: plantdata.list,
        }
    },
    methods: {
        focusPlant(plant) {
            focus.plant = plant;
            currentPage.page = 'focus';
            navigationClick.state = false;
        }
    }
}
</script>
<style scoped>
ul {
    display: flex;
    flex-flow: row wrap;
    justify-content: space-between;
}

li {
    display: flex;
    flex-flow: column nowrap;
    justify-content: flex-start;
    align-items: center;
    flex: 0 0 40%;
    margin: 0 0 5vh;
    color: var(--mainTextColor);
    cursor: pointer;
}

li:hover {
    color: black;
}

figure {
    width: clamp(5vw, 30vw, 150px);
    height: clamp(5vw, 30vw, 150px);
    border: 2px solid var(--mainBgColor);
    border-radius: clamp(2.5vw, 15vw, 75px);
    overflow: hidden;
    background-color: lightgrey;
    margin: 0 auto 1vh;
    overflow: visible;
    position: relative;
}

img {
    width: 120%;
    height: auto;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

figcaption {
    background-color: var(--mainBgColor);
    color: inherit;
    text-align: center;
    padding: 1vh;
}
</style>