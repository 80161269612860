<template>
    <button @click="buttonCheck()" type="button" :class="{ active: isActive}">
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><!--! Font Awesome Pro 6.2.0 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2022 Fonticons, Inc. --><path d="M512 64c0 113.6-84.6 207.5-194.2 222c-7.1-53.4-30.6-101.6-65.3-139.3C290.8 78.3 364 32 448 32h32c17.7 0 32 14.3 32 32zM0 128c0-17.7 14.3-32 32-32H64c123.7 0 224 100.3 224 224v32 96c0 17.7-14.3 32-32 32s-32-14.3-32-32V352C100.3 352 0 251.7 0 128z"/></svg>
    </button>
</template>

<script>
import { navigationClick } from '@/main';

export default {
    name: 'NtNavToggle',
    computed: {
        isActive() {
            return navigationClick.state;
        }
    },
    methods: {
        buttonCheck() {
            navigationClick.state ? navigationClick.state = false : navigationClick.state = true;
        },
    },
}
</script>
<style scoped>
button {
    background: none;
    fill: var(--mainBgColorTransparent);
    stroke: var(--mainBgColorTransparent);
    stroke-width: 3vh;
    border: none;
}

button:hover {
    fill: none;
}

.active {
    fill: var(--mainTextColorTransparent);
    stroke: none;
}

.active:hover {
    fill: none;
    stroke: var(--mainTextColorTransparent);
}

svg {
    width: 4vh;
    height: 4vh;
    fill: inherit;
}
</style>