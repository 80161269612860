<template>
    <button type="button" @click="buttonAction">
        <figure>
            <figcaption v-if="language">{{ figure.text.en }}</figcaption>
            <figcaption v-else>{{ figure.text.de }}</figcaption>
            <img :src="figure.url" :alt="figure.alt">
        </figure>
    </button>
</template>
<script>
import { currentLang } from "@/main";
import { currentPage } from "@/main";
import promptsdata from "@/data/prompts.json";
import { currentPromptNumber } from "@/main";

export default {
    name: 'NtFooterBtn',
    props: {
        figure: Object,
    },
    computed: {
        language() {
            return currentLang.language.state;
        },
    },
    methods: {
        buttonAction() {
            if (currentPage.page === 'home' || currentPage.page === 'focus') {
                currentPage.page = 'overview';
            } else if (currentPage.page === 'prompt') {
                let numberOfPrompts = promptsdata.list.length;
                currentPromptNumber.number = Math.floor(Math.random() * numberOfPrompts) + 1;
            }
        },
    },
}
</script>
<style scoped>
button {
    background-color: var(--mainTextColorTransparent);
    color: var(--mainTextColor);
    border: none;
    font-weight: bold;
    padding: 2vw;
}

button:hover {
    color: black;
}

img {
    width: 5vh;
    height: auto;
}

figcaption {
    background-color: var(--mainBgColor);
    color: inherit;
    padding: 0.4vh;
    font-weight: inherit;
    margin: 0 0 1vh;
}
</style>